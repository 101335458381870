@import '../../assets/css/style/common/mixins';

.checkoutpg {
  min-height: 100vh;

  .process-wrap {
    width: 100%;
    margin: 10px auto;

    @include breakpoint("medium") {
      width: 50%;
      margin: 50px auto;
    }

    .col-3 {
      width: 25%;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    margin: 0 1.6rem;
    font-size: 14px;

    @include breakpoint("medium") {
      margin: 0 3.5rem;
    }

    .order__summary table {
      th,
      td {
        font-size: 11px;

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .payment__method {
    li {
      padding: 0.5em;
      cursor: pointer;

      .left--section {
        height: max-content;
        padding: 5px;
      }

      .right--section {
        display: none;
        width: 62%;
        font-weight: lighter;
        border-left-width: 0;
        height: 100%;
        padding: 0.25em;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;

        h5 {
          margin: 0 0 0.5em;
          font-size: 17px;
        }

        p {
          margin: 0;
        }
      }

      &.active {
        background: white;
        font-weight: bold;
        display: flex;
        justify-content: space-between;

        .left--section {
          border: 1px solid lightseagreen;
          border-left-width: 3px;
          border-right-width: 0;
          height: max-content;
          padding: 5px;
          border-radius: 5px 0 0 5px;
        }

        .right--section {
          display: initial;
        }
      }
    }
  }
}

.checkout {
  main {
    margin: 0 1rem;
    font-size: 14px;

    .header-row {
      background: var(--blue);
      padding: 0.5em;
      color: white;
      margin: 0 -20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      h4 {
        font-size: 14px;
        margin: 0 0 0 12px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }

    input[type="text"] {
      width: 100%;
      box-sizing: border-box;
      padding: 0.5em;
      margin-top: 1em;
      background: var(--light);
      border: 0.5px solid var(--grey);
    }
    &::placeholder {
      color: var(--grey);
    }
    select {
      width: 100%;
      margin-top: 1em;
      padding: 0.5em;
      background: var(--light);
      border: 0.5px solid var(--grey);
    }
    span {
      display: flex;
      align-items: flex-end;
      color: darken($color: grey, $amount: 10);
      p {
        margin: 0;
        margin-top: 1em;
      }
      #ship {
        margin-left: 0;
      }
    }

    .address__Details {
      line-height: 1.5;
      margin-bottom: 1em;
      border: 1px solid #ccc;
      padding: 0 20px 20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      .header-row {
        display: flex;
        justify-content: space-between;
      }
      .icon {
        position: relative;
        &::before {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content: "\f055";
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          position: absolute;
          top: -24px;
          right: 0;
          font-size: 16px;
          cursor: pointer;
        }
        &.expanded {
          &::before {
            content: "\f056";
          }
        }
      }
      .hidden {
        display: none;
      }
      .available__address {
        h4 {
          font-size: 20px;
          margin: 0;
          margin-top: 1em;
        }
      }
      .address {
        display: flex;
        align-items: center;

        .address-detail {
          margin: 0 0.5em;
          padding: 0.5em;
          .name {
            font-size: 18px;
            font-weight: bold;
          }
          p {
            margin: 0;
          }
        }
        button {
          padding: 0.5em 1em;
          background: var(--blue);
          color: white;
          border: none;
        }
        input[type="radio"] {
          margin: 0;
        }
      }
    }

    .order__summary {
      color: darken($color: grey, $amount: 10);
      line-height: 1.5;
      margin-bottom: 1em;
      border: 1px solid #ccc;
      padding: 0 20px 20px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      table {
        width: 100%;
        border-collapse: collapse;
        th {
          font-size: 14px;
          border-bottom: 2px solid grey;
          color: black;
        }
        tr {
          border-bottom: 1px solid lightgrey;
        }
        tbody {
          tr {
            border-bottom: 1px dotted lightgrey;
          }
        }
        tfoot {
          white-space: nowrap;
          td {
            padding: 0.3rem 0;
          }
          tr {
            &:last-child {
              color: black;
              font-size: 16px;
              text-transform: uppercase;
            }
          }
        }
      }

      .cta {
        text-align: center;
        margin: 20px 0;

        .primary-cta {
          width: 75%;
        }
      }
      .left {
        text-align: left;
      }
      .center {
        text-align: center;
      }
      .product--desc {
        padding: 0.5em 0;
      }
      .p-1 {
        padding: 0.5em 1em;
      }
      .product {
        margin-top: 1rem;
      }

      .product__name {
        grid-column: 1;
        grid-row: 1;
        place-self: baseline;
      }
      .quantity {
        grid-column: 2;
        grid-row: 1;
        margin: 0 1em;
      }
      .subtotal {
        grid-column: 3;
        grid-row: 1;
        margin: 0 1em;
      }
    }
  }
  .payment__method {
    font-size: 16px;
    margin-bottom: 1em;
    .header-row {
      margin-bottom: 1em;
      line-height: 1.35;
    }
    .payment {
      position: relative;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 35%;
    }
    li {
      padding: 0.5em;
      border: 1px solid lightgrey;
      background: var(--light);
      border-radius: 5px 0 0 5px;
      cursor: pointer;
      &.active {
        border: 1px solid lightseagreen;
        border-left-width: 3px;
        border-right-width: 0;
        background: white;
        font-weight: bold;
        .right--section {
          display: initial;
        }
      }
    }
    .right--section {
      display: none;
      position: absolute;
      right: 0;
      width: 62%;
      top: 0;
      font-weight: lighter;
      border: 1px solid lightseagreen;
      border-left-width: 0;
      height: 100%;
      padding: 0.25em;
      box-sizing: border-box;
      h5 {
        margin: 0 0 0.5em;
        font-size: 17px;
      }
      p {
        margin: 0;
      }
      button {
        padding: 0.75em;
        width: 100%;
        background: orange;
        margin: 1em 0;
        font-size: 15px;
        color: white;
        text-transform: uppercase;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
}

@include breakpoint(medium) {
  .checkout {
    header {
      position: sticky;
      top: 1.55rem;

      .header-menu {
        position: static;
        margin-right: 2.7rem;
      }
    }

    main {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      grid-gap: 2em;
      margin: 0 4.5rem;
      margin-top: 1.7rem;

      .order__summary table {
        th,
        td {
          font-size: 13px;
        }
      }
    }

    .header-row {
      background: var(--blue);
      color: white;
      padding: 1rem;

      h4 {
        margin: 0;
        font-weight: lighter;
        font-size: 18px;
      }
    }

    .group {
      display: flex;
      gap: 0.5em;
    }
  }
}