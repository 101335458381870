/* importing fonts file */

@import url(fonts.scss);

/* css variables */
@import url(variables.scss);

body {
  font-family: "Arial";
}

.container {
  width: calc(100% - 5px);
}
