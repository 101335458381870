@import './mixins';

.breadcrumbs-wrapper {
  width: 90%;
  margin: 0 auto 50px auto;
}

.breadcrumbs {
  list-style: none;
  padding: 0;
  min-height: 60px;
  margin: 0 0 20px;

  .breadcrumbs-bg {
    float: left;
    background: var(--blue);
    padding: 5px 20px;
    border-radius: 20px;
  }
  
  li {
    margin: 0;
    padding: 0;
    float: left;
    font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 20px;
    color: #c3c2c9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 120px;

    @include breakpoint(medium) {
      text-overflow: unset;
      max-width: unset;
    }
  ;

    &:first-child {
      font-size: 19px;
    }

    a {
      display: block;
      padding: 0 40px 0 0;
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      height: 20px;
      position: relative;
      perspective: 700px;

      &:after {
        content: "";
        width: 20px;
        height: 20px;
        border-color: #fff;
        border-style: solid;
        border-width: 1px 1px 0 0;
        -webkit-backface-visibility: hidden;
        outline: 1px solid transparent;
        position: absolute;
        right: 20px;
        transition: all 0.15s ease;
        transform: rotateZ(45deg) skew(10deg, 10deg);
      }

      &:hover:after {
        right: 15px;
        transform: rotateZ(45deg) skew(-10deg, -10deg);
      }
    }
  }
}