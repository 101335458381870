.pagination {
  display: flex;
  gap: 5px;
  list-style: none;
  cursor: pointer;
  padding: 0;

  a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--blue);
    color: var(--blue);
    font-weight: 900;
  }

  .pagination__link {
    &--active a {
      color: #fff;
      background: var(--blue);
    }

    &--disabled a {
      color: rgb(198, 197, 202);
      border: 1px solid rgb(198, 197, 202);
    }
  }
}
