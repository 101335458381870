.banner {
    display: block;
    width: 90%;
    margin: 0 auto 2em auto;
    position: relative;

    img {
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        min-height: 110px;
    }
}