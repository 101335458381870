@import '../../../../assets/css/style/common/mixins';

.search-container {
  width: 100%;

  @include breakpoint('small') {
    max-width: 320px;
  }

  @include breakpoint('large') {
    max-width: 350px;
  }

  ul li svg {
    display: none;
  }

  .ellipsis {
    align-items: center;
    display: flex;
  }

  .search-icon {
    fill: var(--blue);
  }
}