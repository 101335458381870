@import '../../../assets/css/style/common/mixins';

.footer-container {
  footer {
    background: var(--dark);
    padding: 1em 1.5em;
    margin-bottom: 2.9em;
    position: relative;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        color: white;
        font-size: 35px;
        margin-top: 0.5rem;
      }
      .signup {
        margin-bottom: 1em;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;
        a {
          white-space: nowrap;
        }

        input[type="text"] {
          padding: 0.55em;
          border: none;
          height: 25px;
          width: 70%;
          &:focus {
            outline: none;
            border: 1px solid var(--grey);
          }
        }

        ::placeholder {
          color: var(--grey);
        }

        button {
          border: none;
          background: rgb(250, 135, 3);
          padding: 0.48em 1.5em;
          &:focus {
            outline: none;
            border: 1px solid var(--grey);
          }
        }
      }
    }
    a,
    p {
      color: white;
    }
    .footer-links {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      a {
        font-size: 15px;
      }
      .links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        a {
          margin: 0.25em 0;
        }
      }
      .links.last {
        a {
          font-size: 15px;
        }
      }
    }

    .copyright {
      font-size: 13px;
      color: var(--grey);
      margin: 1em 0;
      text-align: center;
    }

    .social-links {
      display: flex;
      justify-content: center;
      margin-top: 0.7rem;
      .links {
        margin: 0.5em;
      }
      a {
        color: white;
      }
    }
  }

  .menu-bar {
    position: fixed;
    bottom: 0;
    background: var(--light);
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: space-between;

    .menu {
      margin: 1em;
    }
    a {
      color: black;
    }
    a.active {
      color: var(--blue);
      position: relative;
    }

    a.active::after {
      content: "";
      position: absolute;
      background: var(--blue);
      height: 4px;
      width: 150%;
      top: -1em;
      left: -0.3em;
    }
  }

  #cart {
    position: relative;
    .count {
      position: absolute;
      font-size: 12px;
      background: var(--blue);
      color: white;
      border-radius: 50%;
      text-align: center;
      top: -10px;
      right: -7px;
      padding: 0.2em;
      height: 1em;
      width: 1em;
    }
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: absolute;
    left: 10%;
    right: 10%;
    background: var(--light);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .social-items {
      padding: 0.5em;
      img {
        height: 25px;
        margin: 0;
      }
    }
  }

  @include breakpoint(medium) {
    .social {
      flex-direction: column;
      z-index: 100;
      left: 0;
      top: 20%;
      align-self: flex-start;
      justify-content: flex-start;
      align-items: flex-start;
      width: 3em;
      border-radius: 0em;
      position: fixed;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    footer {
      margin-bottom: 0;
      display: grid;
      grid-template-columns: auto minmax(0.5rem, 0.3fr) minmax(auto, 22rem);
      // grid-template-rows: 1fr min-content;
      padding: 1.5em 5% 0.5em 5%;

      .form {
        grid-column: 3;
        grid-row: 1;
        display: block;
        margin: 0 auto;
        position: relative;
        top: -13px;
        width: 100%;
        h1 {
          margin-bottom: 1rem;
        }

        .signup input[type="text"] {
          width: 100%;
        }
      }
      .signup {
        display: flex;
        flex-wrap: nowrap;
      }

      .social-links {
        position: relative;
        right: -7px;
        top: -1rem;
        grid-row: 2;
        justify-self: right;
        grid-column: 3;
        margin: 0;
      }

      .footer-links {
        justify-content: space-between;
      }
      .copyright {
        grid-column: 1;
        position: relative;
        grid-row: 2;
        margin: 0;
        width: 100%;
        top: -0.5rem;
        text-align: left;
      }
    }

    .menu-bar {
      display: none;
    }
  }
}
