@import '../../../assets/css/style/common/mixins';

.product-details {
  width: 90%;
  overflow: hidden;
  margin: 0 auto 50px auto;

  .heading {
    text-transform: capitalize;

    h1 {
      margin: 0;
      padding: 0;
    }
  }

  .top-div {
    display: grid;
    grid-template-columns: 100%;

    @include breakpoint(medium) {
      grid-template-columns: 40% 50%;
      gap: 5%;
    }
  }

  .product-info {
    font-size: 14px;

    @include breakpoint(medium) {
      font-size: 16px;
    }

    .row {
      padding: 0.25rem 0;
      display: flex;
      align-items: center;
      margin: 0.5rem auto;
      gap: 15px;

      @include breakpoint(medium) {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      h3, h4 {
        margin: 0;
        padding: 0;
      }
    }

    .rating.row {
      .rating-wrapper {
        color: var(--blue);
      }
    }

    .stock.row {
      .out-of-stock {
        color: var(--orange);
      }

      .in-stock {
        color: var(--green);
      }
    }

    .buttons.row {
      margin-bottom: 20px !important;
    }

    .select-dropdown {
      position: relative;
      background-color: #E6E6E6;
      border-radius: 4px;

      * {
        margin: 0;
        padding: 0;
        position: relative;
        box-sizing: border-box;
      }

      select {
        font-size: 1rem;
        font-weight: normal;
        max-width: 100%;
        padding: 8px 24px 8px 10px;
        border: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        margin-top: -2px;
        border-top: 5px solid #aaa;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
    }
  }

  .preview {
    .slick-slide img {
      border: 1px solid #ccc;
      border-radius: 24px;
      max-width: calc(100% - 2px);
    }
    .iiz__hint {
      display: none;
    }

    .image-box {
      padding: 0 1.5rem;
      position: relative;
      margin-bottom: 20px;

      .far {
        position: absolute;
        right: 2rem;
        font-size: 24px;
        color: var(--pink);
      }

      .fas {
        position: absolute;
        right: 2rem;
        top: 2rem;
        font-size: 24px;
        color: var(--blue);
      }

      img {
        margin: 0 auto;
        display: block;
        max-width: 100%;
      }
    }
  }
}



