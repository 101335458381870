@import '../../../assets/css/style/common/mixins';

header {
  background: white;
  z-index: 999;
  position: sticky;
  top: 0;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);

  .header-container {
    align-items: center;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px 5%;
    z-index: 100;

    @media (max-width: 480px) {
      display: block;
      width: 90%;
      margin: 0 auto 2em auto;
      position: relative;
    }
  }

  .speedDial {
    position: fixed;
    right: 3vmax;
    top: 3vmax;
  }

  .speedDialIcon {
    width: 56px;
    height: 56px;
    border-radius: 100%;
  }
}
