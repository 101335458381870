.slick-thumb {
  position: unset;
  display: flex !important;
  gap: 15px;
  justify-content: left;
  margin-top: 30px;
  overflow: hidden;
  overflow-x: auto;
  transition: all .5s ease;

  li {
    width: 140px;
    height: 150px;

    img {
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0.2126 0.7152 0.0722 0 0 0 0 0 1 0" /></filter></svg>#filter');
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      height: 140px;
      margin: 0 0 1.45rem;
      padding: 0;
      border: 1px solid #ccc;
      border-radius: 10px;
    }

    &.slick-active img {
      filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" /></filter></svg>#filter');
      -webkit-filter: grayscale(0);
      filter: grayscale(0)
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    height: 8px; background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: var(--blue);
  }
}