.products-container {
  display: block;
  width: 90%;
  margin: 0 auto 2em auto;
  position: relative;

  .products {
    display: block !important;
    width: 100% !important;
  }
  .slider-container {
    img {
      max-width: 100%;
      background: #fff;
      width: 100%;
    }
  }
}

.products {
  .product {
    text-align: center;
  }
}